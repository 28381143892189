import request from "../request";

const api = {
  // 登入
  login(account, password ,sourceIP){
    return request({
      url: "/bid/bia/user/login",
      method: "post",
      data: {
        Account: account,
        Password: password,
        SourceIP: sourceIP,
        IsUser:true,
      },
    });
  },
  getInfo(data) {
    return request({
      url: "/bid/bia/user/info",
      method: "post",
      data,
    });
  },
  updateUserPassword(data) {
    return request({
      url: "/bid/bia/user/setpassword",
      method: "post",
      data,
    });
  },
};

export default api;
