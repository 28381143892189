<template>
  <div id="sideBar" :class="{ shrinkBar: isCollapse }">
    <template>
      <!-- 有子層 -->
      <el-submenu
        v-if="item.children.length > 0"
        :index="String(item.id)"
        :key="item.id"
      >
        <!-- 父層 -->
        <template slot="title">
          <font-awesome-icon
            v-if="item.children && item.children.length > 0"
            :icon="item.icon"
          ></font-awesome-icon>
          <span class="title-name" slot="title" v-if="!isCollapse">
            {{ item.name }}
          </span>
        </template>
        <!-- 渲染子層路由 子層 -->
        <template v-for="child in item.children">
          <!-- 當子層不隱藏且不是按鈕時 -->
          <el-menu-item
            :key="child.id"
            :index="child.path"
            @click="$emit('doRoute', child.path)"
          >
            <font-awesome-icon :icon="child.icon" />
            <template slot="title">
              <span class="title-name" slot="title">{{ child.name }}</span>
            </template>
          </el-menu-item>
        </template>
      </el-submenu>

      <!-- 無子層 -->
      <template v-else>
        <el-menu-item
          v-if="item.name !== '登出'"
          :index="item.path"
          :key="item.id"
          @click="$emit('doRoute', item.path)"
        >
          <font-awesome-icon :icon="item.icon" />
          <template slot="title">
            <span class="title-name" slot="title">{{ item.name }}</span>
          </template>
        </el-menu-item>
        <el-menu-item v-else :index="item.path" @click="signout()">
          <font-awesome-icon :icon="item.icon" />
          <template slot="title">
            <span class="title-name" slot="title">{{ item.name }}</span>
          </template>
        </el-menu-item>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
      required: false,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  methods: {
    signout() {
      this.$confirm("確定要登出系統嗎？", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("loadingHandler", true);

        this.$store.dispatch("Logout").then(() => {
          window.localStorage.removeItem("userID");
          this.$notify({
            title: "成功",
            message: "您已成功登出!",
            type: "success",
          });
          this.$router.push("/");
        });

        this.$store.dispatch("loadingHandler", false);
      });
    },
  },
};
</script>

<style lang="scss">
#sideBar {
  &.shrinkBar {
    li,
    .el-tooltip {
      padding: 0 !important;
      text-align: center !important;
      i {
        margin-right: 0 !important;
      }
    }
  }

  .title {
    &-name {
      padding-left: 8px;
    }
  }
}
</style>
