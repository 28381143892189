import request from "../request";

const api = {
  /* 取得名單狀態*/
  statistics(data) {
    return request({
      url: "/bid/aim/list/statistics",
      method: "post",
      data,
    });
  },
  /* 警示名單彙總表*/
  searchList(data) {
    return request({
      url: "/bid/aim/list/search",
      method: "post",
      data,
    });
  },
  /* 案件管理頁 (承辦) 送審 */
  waitApproval(data) {
    return request({
      url: "/bid/aim/list/wait_approval",
      method: "post",
      data,
    });
  },
  /* 案件管理頁 (承辦) 送審刪除 */
  waitDelete(data) {
    return request({
      url: "/bid/aim/list/wait_delete",
      method: "post",
      data,
    }); 
  },
  /* (承辦)儲存表單 */
  setInfo(data) {
    return request({
      url: "/bid/aim/list/setinfo",
      method: "post",
      data,
    });
  },
  /* (承辦)已覆核案件加入關聯資訊 */
  addRelationInfo(data) {
    return request({
      url: "/bid/aim/list/set_relation_data",
      method: "post",
      data,
    });
  },
  /* 案件管理頁 (主管) 覆核同意 */
  approval(data) {
    return request({
      url: "/bid/aim/list/approval",
      method: "post",
      data,
    });
  },
  /* 案件管理頁 (主管) 覆核刪除 */
  delete(data) {
    return request({
      url: "/bid/aim/list/delete",
      method: "post",
      data,
    });
  },
  /* 案件管理頁 (主管) 覆核退回 */
  reject(data) {
    return request({
      url: "/bid/aim/list/reject",
      method: "post",
      data,
    });
  },
  /* 取得新增時的表單ID */
  addList(data) {
    return request({
      url: "/bid/aim/list/addlist",
      method: "post",
      data,
    });
  },
  /*編輯時get資料*/
  editinfo(data) {
    return request({
      url: "/bid/aim/list/editinfo",
      method: "post",
      data,
    });
  },
  /*檢視時get資料*/
  latestinfo(data) {
    return request({
      url: "/bid/aim/list/latestinfo",
      method: "post",
      data,
    });
  },
  //上傳照片
  uploadImg(data) {
    return request({
      url: "/bid/aim/list/upload_base_pic",
      headers: { "Content-Type": "multipart/form-data" },
      method: "post",
      data,
    });
  },
  //上傳附件步驟1
  uploadFile(data) {
    return request({
      url: "/bid/aim/list/upload_file",
      headers: { "Content-Type": "multipart/form-data" },
      method: "post",
      data,
    });
  },
  //上傳附件步驟2
  uploadAttachement(data) {
    return request({
      url: "/bid/aim/list/upload_attachement",
      method: "post",
      data,
    });
  },
  //取得照片或附件檔案
  download(data) {
    return request({
      url: "/bid/aim/list/download",
      method: "post",
      data,
    });
  },
  //刪除檔案
  delFile(data) {
    return request({
      url: "/bid/aim/list/delete_file",
      method: "post",
      data,
    });
  },

};

export default api;
