var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.loading
    ? _c("div", { attrs: { id: "loadingPage" } }, [
        _c("div", { staticClass: "BG" }),
        _vm._m(0)
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Icon" }, [
      _c("div", { staticClass: "Icon__circle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }