import request from "../request";

const api = {
  /* 登入紀錄 */
  loginRecord(data) {
    return request({
      url: "/bid/aim/record/login",
      method: "post",
      data,
    });
  },
  /* 操作紀錄 */
  actionRecord(data) {
    return request({
      url: "/bid/aim/record/action",
      method: "post",
      data,
    });
  },
  /* 簽核歷程紀錄 */
  getPreviewList(data) {
    return request({
      url: "/bid/aim/record/list",
      method: "post",
      data,
    });
  },
};

export default api;
