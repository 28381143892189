
import dayjs from "dayjs";

export default{
    // const dayjsPlugin = {
    //     install(Vue, options) {
    //         Vue.prototype.$myDay = dayjs
    //     }
    // };
    install(Vue, options) {
        Vue.prototype.$dayjs = dayjs;
      }
}