var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("Loading"),
      _c(
        "div",
        { attrs: { id: "nav" } },
        [
          !_vm.$route.meta.hideMenu
            ? _c(
                "el-aside",
                {
                  staticClass: "mainLayoutAside",
                  class: { isCollapse: _vm.isCollapse }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mainLayoutAside__title",
                      class: { mainLayoutAside__shrink: _vm.isCollapse },
                      style: { "flex-direction: column": !_vm.isCollapse }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.logoImg,
                          alt: "logo",
                          width: _vm.getLogoWidth
                        }
                      }),
                      _c("i", {
                        staticClass: "el-icon-s-unfold",
                        class: { goRound: !_vm.isCollapse },
                        on: {
                          click: function($event) {
                            _vm.isCollapse = !_vm.isCollapse
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isCollapse,
                          expression: "!isCollapse"
                        }
                      ],
                      staticClass: "userNameWrap"
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.getUserProfile.UserName
                              ? _vm.getUserProfile.UserName
                              : "使用者"
                          ) + " 您好"
                        )
                      ])
                    ]
                  ),
                  _c(
                    "el-menu",
                    {
                      attrs: {
                        "default-active": _vm.$route.name,
                        "show-timeout": 200,
                        "collapse-transition": false,
                        "unique-opened": _vm.uniqueOp,
                        collapse: _vm.isCollapse,
                        "background-color": "#191972",
                        "text-color": "#fff",
                        "active-text-color": "#ffd04b",
                        router: false
                      }
                    },
                    _vm._l(_vm.routes, function(menu) {
                      return _c("Sidebar", {
                        key: menu.id,
                        attrs: { isCollapse: _vm.isCollapse, item: menu },
                        on: { doRoute: _vm.doRoute }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-main",
            {
              class: { withUp: _vm.isCollapse, noBar: _vm.$route.meta.hideMenu }
            },
            [_c("div", { staticClass: "viewHeight" }, [_c("router-view")], 1)]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }