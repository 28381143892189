var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { shrinkBar: _vm.isCollapse }, attrs: { id: "sideBar" } },
    [
      [
        _vm.item.children.length > 0
          ? _c(
              "el-submenu",
              { key: _vm.item.id, attrs: { index: String(_vm.item.id) } },
              [
                _c(
                  "template",
                  { slot: "title" },
                  [
                    _vm.item.children && _vm.item.children.length > 0
                      ? _c("font-awesome-icon", {
                          attrs: { icon: _vm.item.icon }
                        })
                      : _vm._e(),
                    !_vm.isCollapse
                      ? _c(
                          "span",
                          {
                            staticClass: "title-name",
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [_vm._v(" " + _vm._s(_vm.item.name) + " ")]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._l(_vm.item.children, function(child) {
                  return [
                    _c(
                      "el-menu-item",
                      {
                        key: child.id,
                        attrs: { index: child.path },
                        on: {
                          click: function($event) {
                            return _vm.$emit("doRoute", child.path)
                          }
                        }
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: child.icon }
                        }),
                        _c("template", { slot: "title" }, [
                          _c(
                            "span",
                            {
                              staticClass: "title-name",
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [_vm._v(_vm._s(child.name))]
                          )
                        ])
                      ],
                      2
                    )
                  ]
                })
              ],
              2
            )
          : [
              _vm.item.name !== "登出"
                ? _c(
                    "el-menu-item",
                    {
                      key: _vm.item.id,
                      attrs: { index: _vm.item.path },
                      on: {
                        click: function($event) {
                          return _vm.$emit("doRoute", _vm.item.path)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: _vm.item.icon }
                      }),
                      _c("template", { slot: "title" }, [
                        _c(
                          "span",
                          {
                            staticClass: "title-name",
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [_vm._v(_vm._s(_vm.item.name))]
                        )
                      ])
                    ],
                    2
                  )
                : _c(
                    "el-menu-item",
                    {
                      attrs: { index: _vm.item.path },
                      on: {
                        click: function($event) {
                          return _vm.signout()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: _vm.item.icon }
                      }),
                      _c("template", { slot: "title" }, [
                        _c(
                          "span",
                          {
                            staticClass: "title-name",
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [_vm._v(_vm._s(_vm.item.name))]
                        )
                      ])
                    ],
                    2
                  )
            ]
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }