import request from "../request";

const api = {
  // 首頁
  /* 關聯新聞 */
  searchNews(data) {
    return request({
      url: "/bid/bia/data/news/search_news",
      method: "post",
      data,
    });
  },
  //聯合國制裁名單
  searChsan(data) {
    return request({
      url: "/bid/aim/data/searchsan",
      method: "post",
      data,
    });
  },
  //政府拒絕往來名單
  searchConstr(data) {
    return request({
      url: "/bid/aim/data/searchconstr",
      method: "post",
      data,
    });
  },
  //違反勞動法名單
  searchLabor(data) {
    return request({
      url: "/bid/aim/data/searchlabor",
      method: "post",
      data,
    });
  },
  //環保署裁罰名單
  searchEnviroment(data) {
    return request({
      url: "/bid/aim/data/searchenviroment",
      method: "post",
      data,
    });
  },
};

export default api;
